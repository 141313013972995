import { addJourneyMessages, sleep } from "../helpers/addJourneyMessages";
import createMessage from "../helpers/createMessage";
import { EnumEnquiryModules } from "../components/EnquiryOptions/Constants";
import { initializePickupSchedule } from "../modules/RepairClaim/state/operators/scheduleOperator";
import {
  setCaseType,
  setEnquiryOption,
} from "../reducers/journeyMessagesReducer";
import {
  PROGRESS_DATA,
  API_PROGRESS,
  COMPONENTS,
  ACTIVITY,
  INCIDENT_PATH_TYPE,
} from "../helpers/constants";
import {
  updateFlowProgress,
  updateApiProgress,
  clearApiProgress,
  serviceUnavailable,
} from "./flowWindow";
import {
  cancelServiceRequest,
  determineRequest,
  resumeServiceRequest,
  caseGraphApi
} from "./serviceRequestApi";
import updateVisitor from "../modules/Appsync/updateVisitor";
import {
  INCIDENT_SCREEN_REPAIR,
  INCIDENT_SCREEN_REPAIR_WARRANTY,
} from "../components/EnquiryOptions/Constants";
import { setChatReason } from "../modules/Appsync/state/reducers";
import { udpateChatInputVisibility } from "../modules/Appsync/state/operators";
import { isEmpty } from "../helpers/formatUtils";
import { getServiceFee } from "../modules/RepairClaim/state/actions/paymentApi";
import { setDeviceMakeAndModel } from "../modules/RepairClaim/state/actions/actions";
import { getEnrolledServiceFee } from "./cowrapperapi";
import { setLastCompletedCase } from "../reducers/serviceRequestReducer";

const newRequestFlow = (labelMessage) => async (dispatch, getStore) => {
  dispatch(initializePickupSchedule());
  const initial = getStore().journeyMessages.flowProgress.percentage;
  await dispatch(
    updateFlowProgress(
      PROGRESS_DATA.CONFIRM_DEVICE.title,
      PROGRESS_DATA.CONFIRM_DEVICE.count,
      initial
    )
  );
  dispatch(
    addJourneyMessages([
      labelMessage,
      createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent: "preparation",
      }),
    ])
  );
};

const cancelPreviousSRInBackground = () => async (dispatch, getStore) => {
  // cancel SR
  await dispatch(
    updateApiProgress(API_PROGRESS.TROUBLESHOOT, 40, COMPONENTS.ENQUIRY_OPTIONS)
  );
  const { CacheId } = getStore().session.sessionData.Configurations;
  const { ServiceRequestId, CustomerCaseId } =
    getStore().serviceRequest.determineIncidentDetails;
  const note = "Request Cancelled by User";

  const selectedAgreement = getStore().validation.agreement;

  const cancelSRResponse = await dispatch(
    cancelServiceRequest(
      CacheId,
      ServiceRequestId,
      note,
      "",
      CustomerCaseId,
      "",
      ""
    )
  ).catch((err) => dispatch(serviceUnavailable()));
  if (isEmpty(cancelSRResponse)) return;

  const determineResponse = await dispatch(determineRequest(CacheId)).catch(
    (err) => dispatch(serviceUnavailable())
  );
  if (isEmpty(determineResponse)) return;

  let caseGraphResponse = await dispatch(
    caseGraphApi(CacheId)
  ).catch((err) => {
    console.log("Case Graph API error:", err)
    return null;
  });

  console.log("caseGraphResponse", caseGraphResponse);

  if (caseGraphResponse && caseGraphResponse.CustomerCaseGraphResponse && 
    caseGraphResponse.CustomerCaseGraphResponse.CustomerCases && 
    caseGraphResponse.CustomerCaseGraphResponse.CustomerCases.length > 0) {
    let selectedAgreementCustomerCases = caseGraphResponse.CustomerCaseGraphResponse.CustomerCases.filter(
      (customerCase) => {
        console.log(customerCase)
        return (customerCase.AgreementId === selectedAgreement.AgreementId && 
          customerCase.IncidentType.toLowerCase().indexOf("screen repair") > -1 &&
          customerCase.CustomerCaseStatus === "CMPLTD" &&
          (customerCase.ServiceRequests &&
            customerCase.ServiceRequests.length &&
            customerCase.ServiceRequests[0] &&
            customerCase.ServiceRequests[0].ShippingOrder &&
            customerCase.ServiceRequests[0].ShippingOrder.ShippingStatus === "SHIP"))
      }
    )

    console.log("selectedAgreementCustomerCases", selectedAgreementCustomerCases);

    let casesToSort = [];

    if (selectedAgreementCustomerCases && selectedAgreementCustomerCases.length > 0) {
      casesToSort = [...selectedAgreementCustomerCases]
      casesToSort.sort((a, b) => new Date(a.ServiceRequests[0].ShippingOrder.ShipmentDate) - new Date(b.ServiceRequests[0].ShippingOrder.ShipmentDate));
    }

    if (casesToSort.length) {
      await dispatch(setLastCompletedCase(casesToSort[0]))
    }
  }

  await dispatch(
    updateApiProgress(
      API_PROGRESS.TROUBLESHOOT_SUCCESS,
      100,
      COMPONENTS.ENQUIRY_OPTIONS,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
};

export const initModuleTrigger = (option) => async (dispatch, getStore) => {
  // console.log(option.name, "module is selected");
  const labelMessage = createMessage("TEXT", "user", option.label);
  dispatch(saveEnquiryOption(option.name));

  if (option.name === "NewRequest") {
    dispatch(saveCaseType(INCIDENT_SCREEN_REPAIR));
  } else if (option.name === "In-WarrantyRequest") {
    dispatch(saveCaseType(INCIDENT_SCREEN_REPAIR_WARRANTY));
  }

  dispatch(
    updateVisitor({
      lastActivity: `${option.name} ${ACTIVITY.ENQUIRY_OPTIONS}`,
      interactionType: option.name,
    })
  );

  const incidentPathType = getStore().serviceRequest.type;
  let showComponent = "";
  let showModule = "";

  switch (option.name) {
    case EnumEnquiryModules.NewRequest:
      if (incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST) {
        cancelPreviousSRInBackground();
      }

      const {
        interactionData: {
          Interaction: {
            InteractionLineId,
            SessionData: { ClientId, ClientChannelId, ClientName },
          },
        },
      } = getStore().session;
      const { mdn } = getStore().validation.inputData;
      dispatch(
        getEnrolledServiceFee(
          ClientId,
          ClientChannelId,
          ClientName,
          InteractionLineId,
          mdn
        )
      );

      await dispatch(newRequestFlow(labelMessage));

      break;
    case EnumEnquiryModules.InWarrantyRequest:
      {
        const {
          interactionData: {
            Interaction: {
              InteractionLineId,
              SessionData: { ClientId, ClientChannelId, ClientName },
            },
          },
        } = getStore().session;
        const { mdn } = getStore().validation.inputData;
        const { CacheId } = getStore().session.sessionData.Configurations;

        dispatch(
          getEnrolledServiceFee(
            ClientId,
            ClientChannelId,
            ClientName,
            InteractionLineId,
            mdn,
            CacheId
          )
        );

        dispatch(initializePickupSchedule());
        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(
          updateFlowProgress(
            PROGRESS_DATA.PREPARE_REQUIREMENTS.title,
            PROGRESS_DATA.PREPARE_REQUIREMENTS.count,
            initial
          )
        );
        dispatch(
          addJourneyMessages([
            labelMessage,
            createMessage("DEVICE_CONFIRMATION", "system", {
              showComponent: "preparation",
            }),
          ])
        );
      }

      break;
    case EnumEnquiryModules.ChangeSchedule:
      dispatch(addJourneyMessages([labelMessage]));
      // initiating chat
      await dispatch(setChatReason(option.name));
      dispatch(udpateChatInputVisibility(true));
      break;
    // case EnumEnquiryModules.ChangeSchedule:
    //   dispatch(addJourneyMessages([labelMessage]));
    //   await dispatch(initializePickupSchedule());
    //   dispatch(
    //     addJourneyMessages([
    //       createMessage("PICKUP_AND_DELIVERY", "system", {
    //         showComponent: "confirmPickupAddress",
    //       }),
    //     ])
    //   );
    //   break;
    case EnumEnquiryModules.CancelRequest:
      const { ServiceRequestType, RepairRequest } =
        getStore().serviceRequest.determineIncidentDetails;

      if (
        incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED &&
        ServiceRequestType !== "SCRNREPREQ"
      ) {
        // device in-eligible for screen repair
        showModule = "DEVICE_CONFIRMATION";
        showComponent = "deviceNotEligible";
      } else if (
        RepairRequest &&
        (RepairRequest.RepairStatus === "RESERVED" ||
          RepairRequest.RepairStatus === "SCHEDULED")
      ) {
        await dispatch(initializePickupSchedule());
        showComponent = "";
        showModule = "REPAIR_DETAILS";
      } else if (
        RepairRequest &&
        (RepairRequest.RepairStatus !== "RESERVED" ||
          RepairRequest.RepairStatus !== "SCHEDULED")
      ) {
        // redirect to chat
        dispatch(addJourneyMessages([labelMessage]));
        // initiating chat
        await dispatch(setChatReason(option.name));
        dispatch(udpateChatInputVisibility(true));
        return;
      } else if (
        incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST
      ) {
        showModule = "CANCEL_REPAIR";
        showComponent = "ConfirmRequestCancel";
      }

      dispatch(
        addJourneyMessages([
          labelMessage,
          createMessage(showModule, "system", { showComponent }),
        ])
      );
      break;
    case EnumEnquiryModules.ResumeRequest:
      const { CacheId } = getStore().session.sessionData.Configurations;
      const { ServiceRequestId, ClaimedAsset } =
        getStore().serviceRequest.determineIncidentDetails;

      dispatch(addJourneyMessages([labelMessage]));
      const module = API_PROGRESS.RESUME_REQUEST,
        module_success = API_PROGRESS.RESUME_REQUEST_SUCCESS,
        component = COMPONENTS.ENQUIRY_OPTIONS;
      await dispatch(updateApiProgress(module, 40, component));

      const resumeSRResponse = await dispatch(
        resumeServiceRequest(CacheId, ServiceRequestId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(resumeSRResponse)) return;

      if (!isEmpty(resumeSRResponse.ServiceRequestDetails.IncidentType)) {
        dispatch(
          saveCaseType(resumeSRResponse.ServiceRequestDetails.IncidentType)
        );
      }

      await dispatch(updateApiProgress(module_success, 100, component, 40));
      await sleep(1000);
      await dispatch(clearApiProgress());

      // redirect to resume points
      const { Actions, SRAssetCatalogName, SRAssetCatalogId } =
        resumeSRResponse.ServiceRequestDetails;

      if (Actions === "CaptureIncidentDetails") {
        const { replacedAsset } = getStore().validation.verification;

        showComponent = isEmpty(replacedAsset)
          ? "confirmDevice"
          : "displayDevices";

        showModule = "DEVICE_CONFIRMATION";
      } else if (
        Actions === "SelectReplacementEquipment" ||
        Actions === "ConfirmShippingAddress" ||
        Actions === "CaptureShippingMethod" ||
        Actions === "OrderFulFillment" ||
        Actions === "CapturePaymentDetails"
      ) {
        // save asset details
        const deviceDetails = {
          AssetCatalog: {
            AssetCatalogId: SRAssetCatalogId,
            AssetCatalogName: SRAssetCatalogName,
          },
          Make: {
            MakeId: "",
            Name: ClaimedAsset.Make,
          },
          Model: {
            ModelId: "",
            Name: ClaimedAsset.Model,
          },
          IMEI: "",
        };
        dispatch(setDeviceMakeAndModel(deviceDetails));

        // calling service fee api here
        // let encodedPerilString = encodeURIComponent(`\"${INCIDENT_SCREEN_REPAIR.toUpperCase()}\",\"${INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase()}\"`);
        const { caseType } = getStore().journeyMessages;
        const caseName = caseType ? caseType : INCIDENT_SCREEN_REPAIR;
        let encodedPerilString = encodeURIComponent(`\"${caseName.toUpperCase()}\"`);
        let serviceFeeResposnse = await dispatch(getServiceFee(CacheId, ServiceRequestId, encodedPerilString))
        .catch((err) => {
          console.log("err", err)
          dispatch(serviceUnavailable())
        });

        console.log("serviceFeeResposnse", serviceFeeResposnse)

        if (isEmpty(serviceFeeResposnse)) return;

        await dispatch(initializePickupSchedule());
        showComponent = "confirmPickupAddress";
        showModule = "PICKUP_AND_DELIVERY";

        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(
          updateFlowProgress(
            PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.title,
            PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.count,
            initial
          )
        );
        dispatch(updateVisitor({ lastActivity: ACTIVITY.TERMS_N_CONDITIONS }));
      }

      dispatch(
        addJourneyMessages([
          createMessage(showModule, "system", { showComponent }),
        ])
      );

      break;
    case EnumEnquiryModules.GeneralEnquiry:
      dispatch(
        addJourneyMessages([
          labelMessage,
          createMessage("GENERAL_ENQUIRY", "system", {
            showComponent: "GeneralEnquiryOptions",
          }),
        ])
      );
      break;
    // case EnumEnquiryModules.InWarrantyRequest:
    //   dispatch(saveCaseType(INCIDENT_SCREEN_REPAIR_WARRANTY));
    //   dispatch(
    //     addJourneyMessages([
    //       labelMessage,
    //       createMessage("GENERAL_ENQUIRY", "system", {
    //         showComponent: "GeneralEnquiryOptions",
    //       }),
    //     ])
    //   );
    //   break;
    default:
      dispatch(addJourneyMessages([labelMessage]));
  }
};

const saveEnquiryOption = (option) => (dispatch) => {
  dispatch({
    type: setEnquiryOption.toString(),
    payload: option,
  });
};

const saveCaseType = (caseType) => (dispatch) => {
  dispatch({
    type: setCaseType.toString(),
    payload: caseType,
  });
};
