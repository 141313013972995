const CONSTANTS = {
  INTERACTION_TYPE: "ONLINE",
  INTERACTION_STATUS: "INPRGRS",
  CHANNEL_NAME: "ONLINE",
  CLIENT_PROFILE_ID: "6040",
  EXTERNAL_SYSTEM_NAME: "ConsoleOne",
  INTERACTION_LINE_REASON: "STRTSR",
  ENGAGEMENT_TYPE: "SUR",
  REQUEST_TYPE: "ScreenRepair",
};

export default CONSTANTS;

export const API_PROGRESS = {
  ASK_MDN: "Your mobile number is being validated",
  ASK_MDN_SUCCESS: "Your mobile number has been validated",
  ASK_NAME: "Your eligibility to access Screen Repair Service is being verified",
  ASK_NAME_SUCCESS: "Your eligibility to access Screen Repair Service has been verified",
  ASK_NRIC: "Your NRIC/FIN number is being verified",
  ASK_NRIC_SUCCESS: "Your NRIC/FIN number has been verified",
  VERIFY_IMEI: "Your IMEI number is being validated",
  VERIFY_IMEI_SUCCESS: "Your IMEI number has been validated",
  CREATE_SR: "Your request is being initiated",
  CREATE_SR_SUCCESS: "Your request has been initiated",
  UPDATE_ADDRESS: "Your delivery address is being updated",
  UPDATE_ADDRESS_SUCCESS: "Your delivery address has been updated",
  UPDATE_SCHEDULE: "Your pick-up & return schedule is being submitted",
  UPDATE_SCHEDULE_SUCCESS: "Your pick-up & return schedule has been submitted",
  CONFIRM_ADDRESS: "Submitting your confirmed Screen Repair Request schedule",
  CONFIRM_ADDRESS_SUCCESS: "Your Screen Repair Request schedule has been submitted",
  TROUBLESHOOT: `Your device's eligibility to access the Screen Repair Service is being verified`,
  TROUBLESHOOT_SUCCESS: `Your device's eligibility to access the Screen Repair Service has been verified`,
  PAYMENT: "You credit card/debit card detail is being validated",
  PAYMENT_SUCCESS: "You credit card/debit card detail is validated",
  REQUEST_SUBMIT: "Your Screen Repair Request is being submitted",
  REQUEST_SUBMIT_SUCCESS: "Your Screen Repair Request has been submitted",
  UPDATE_CONTACT_NUMBER: "Your mobile number is being updated.",
  UPDATE_CONTACT_NUMBER_SUCCESS: "Your mobile number has been updated.",
  UPDATE_CONTACT_EMAIL: "Your email address is being updated.",
  UPDATE_CONTACT_EMAIL_SUCCESS: "Your email address has been updated.",
  RESUME_REQUEST: "Your service request is being resumed.",
  RESUME_REQUEST_SUCCESS: "Your service request has been resumed.",
  CREATE_ENQUIRY: "Your enquiry is being sent.",
  CREATE_ENQUIRY_SUCCESS: "Your enquiry has been sent.",
  GET_SURVEY: "Your survey is being initiated.",
  GET_SURVEY_SUCCESS: "Your survey has been initiated.",
  SUBMIT_RATING: "Your rating is being submitted.",
  SUBMIT_RATING_SUCCESS: "Your rating has been submitted.",
  SUBMIT_FEEDBACK: "You feeback is being submitted.",
  SUBMIT_FEEDBACK_SUCCESS: "You feeback has been submitted.",
};

export const COMPONENTS = {
  ASK_MDN: "ASK_MDN",
  ASK_NAME: "ASK_NAME",
  ASK_NRIC: "ASK_NRIC",
  ENQUIRY_OPTIONS: "ENQUIRY_OPTIONS",
  CONFIRM_DEVICE: "CONFIRM_DEVICE",
  VERIFY_IMEI: "VERIFY_IMEI",
  API_PROGRESS: "API_PROGRESS",
  FLOW_PROGRESS: "FLOW_PROGRESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
  CONFIRM_ADDRESS: "CONFIRM_ADDRESS",
  TROUBLESHOOT: "TROUBLESHOOT",
  PAYMENT: "PAYMENT",
  CONFIRM_REQUEST_DETAILS: "CONFIRM_REQUEST_DETAILS",
  TERMS_N_CONDITIONS: "TERMS_N_CONDITIONS",
  CONFIRM_CONTACT: "CONFIRM_CONTACT",
  CONFIRM_SCHEDULE: "CONFIRM_SCHEDULE",
  UPDATE_CONTACT: "UPDATE_CONTACT",
  CREATE_ENQUIRY: "CREATE_ENQUIRY",
  REQUEST_FEEDBACK: "REQUEST_FEEDBACK",
  SUBMIT_RATING: "SUBMIT_RATING",
  SUBMIT_FEEDBACK: "SUBMIT_FEEDBACK",
};

export const PROGRESS_DATA = {
  ASK_MDN: {
    title: "VALIDATE MOBILE NUMBER",
    count: 0,
  },
  ASK_NAME: {
    title: "VALIDATE FIRST NAME AND LAST NAME",
    count: 15,
  },
  ASK_NRIC: {
    title: "VERIFY NRIC/FIN NUMBER",
    count: 30,
  },
  ENQUIRY_OPTIONS: {
    title: "ENQUIRY",
    count: 38,
  },
  PREPARE_REQUIREMENTS: {
    title: "Prepare Requirements",
    count: 45,
  },
  CONFIRM_DEVICE: {
    title: "CONFIRM DEVICE ELIGIBILITY",
    count: 48,
  },
  VERIFY_IMEI: {
    title: "INPUT IMEI NUMBER",
    count: 58,
  },
  TROUBLESHOOT: {
    title: "TROUBLESHOOTING DEVICE",
    count: 63,
  },
  TERMS_N_CONDITIONS: {
    title: "TERMS AND CONDITIONS",
    count: 70,
  },
  CONFIRM_PICKUP_DETAILS: {
    title: "PICKUP ADDRESS & DATE/TIME",
    count: 80,
  },
  CONFIRM_RETURN_DETAILS: {
    title: "RETURN ADDRESS & DATE/TIME",
    count: 86,
  },
  CONFIRM_SCHEDULE: {
    title: "CONFIRM PICKUP & RETURN ADDRESS DATE/TIME",
    count: 93,
  },
  CONFIRM_CONTACT: {
    title: "CONFIRM SMS & EMAIL",
    count: 93,
  },
  PAYMENT: {
    title: "PAYMENT",
    count: 96,
  },
  CONFIRM_REQUEST_DETAILS: {
    title: "CONFIRM DETAILS",
    count: 98,
  },
  FEEDBACK: {
    title: "",
    count: 100,
  },
};

export const DEVICE = {
  APPLE: "APPLE",
  SAMSUNG: "SAMSUNG",
  HUAWEI: "HUAWEI",
  XIAOMI: "XIAOMI",
};

export const CARD = {
  VISA: "VISA",
  MASTER: "MASTER",
  AMEX: "AMEX",
};

export const REGEX = {
  Email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Visa: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
  Amex: /^(?:3[47][0-9]{13})$/,
  MasterCard: /^(?:5[1-5][0-9]{14})$/,
  Text: /[^\u0E00-\u0E7Fa-zA-Z' ]|^'|'$|''/g,
};

export const IFRAME = {
  EVENT_NAME: "message",
  OPEN_CHAT: "openchat",
};

export const ACTIVITY = {
  ASK_MDN: "MDN Verified",
  ASK_NAME: "Name Verified",
  ASK_NRIC: "NRIC Verified",
  ENQUIRY_OPTIONS: "Enquiry option selected",
  CREATE_ENQUIRY: "New Enquiry created",
  CONFIRM_DEVICE: "Device confirmed",
  VERIFY_IMEI: "IMEI Verified",
  UPDATE_PICKUP_ADDRESS: "Pick-up Address updated",
  UPDATE_DELIVERY_ADDRESS: "Delivery Address updated",
  UPDATE_PICKUP_SCHEDULE: "Pick-up Schedule date/time updated",
  UPDATE_DELIVERY_SCHEDULE: "Delivery Schedule date/time updated",
  CONFIRM_SCHEDULE: "Pick-up Return schedule confirmed",
  TROUBLESHOOT: "Troubleshoot questions submitted",
  PAYMENT: "Payment initiated",
  CONFIRM_REQUEST_DETAILS: "Repair request details confirmed",
  TERMS_N_CONDITIONS: "Agreed to terms and conditions",
  CONFIRM_CONTACT_NUMBER: "Contact number confirmed",
  UPDATE_CONTACT_NUMBER: "Contact number updated",
  CONFIRM_CONTACT_EMAIL: "Email Address confirmed",
  UPDATE_CONTACT_EMAIL: "Email Address updated",
  NEW_REQUEST: "Service Request Created",
  SUBMIT_REQUEST: "Service Request Submitted",
  CANCEL_REQUEST: "Service Request Cancelled",
  RESUME_REQUEST: "Service Request Resumed",
  NPS_RATING: "NPS Rating Submitted",
  NPS_FEEDBACK: "Feedbacl Submitted",
};

export const ONLINE_JOURNEY_STATUS_LIST = {
  STARTED: "Started",
  COMPLETED: "Completed",
  INPROGRESS: "In Progress",
  FAILURE: "Failure",
};

export const INCIDENT_PATH_TYPE = {
  START_SERVICE_REQUEST: "StartServiceRequest",
  RESUME_SERVICE_REQUEST: "ResumeServiceRequest",
  WAIT_FOR_HOLD: "WaitForHoldResolution",
  NO_ACTION_REQUIRED: "NoActionRequired",
};

export const WORDPRESS_CONTENTS = {
  FAQ: {
    PATH: "faq",
    TITLE: "FAQ",
  },
  PREPARE: {
    PATH: "prepare",
    TITLE: "Prepare Your Device",
  },
  TERMS_AND_CONDITIONS: {
    PATH: "terms-and-conditions",
    TITLE: "Terms and Conditions",
  },
  TERMS_OF_USE: {
    PATH: "terms-of-use",
    TITLE: "Terms of Use",
  },
};

export const PAYMENT_METHOD = {
  CREDIT_CARD: "CRE",
  CASH_ON_DELIVERY: "COD",
};

export const FIELD_IDS = {
  MDN: "txt_sur_m1_mdn_id",
  SUBMIT_MDN: "btn_sur_m1_submit_mdn_id",
  FIRST_NAME: "txt_sur_m1_first_name_id",
  LAST_NAME: "txt_sur_m1_last_name_id",
  SUBMIT_NAME: "btn_sur_m1_submit_name_id",
  NRIC: "txt_sur_m1_nric_id",
  SUBMIT_NRIC: "btn_sur_m1_submit_nric_id",
};
