import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  setPickupAddress,
  setDeliveryAddress,
  setPickupDateTime,
  setDeliveryDateTime,
  setPickupOptions,
  setRegisteredAddress,
  setCorrectedPickupAddress,
  setCorrectedDeliveryAddress,
  setFailedPickupAddressAttempt,
  setFailedDeliveryAddressAttempt,
  setisCampaignTimingEligible,
} from "../reducers/scheduleReducer";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  DEVICE
} from "../../../../helpers/constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import {
  getPickupDates,
  getDeliveryDates,
  getMonFriPickupDates
} from "../../../../helpers/dateUtils";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import updateVisitor from "../../../Appsync/updateVisitor";
import { isEmpty } from "../../../../helpers/formatUtils";
import {
  standardizeAddressApi,
  serviceOrderApi,
  getShippingAddressApi,
  updateShippingAddressApi,
  createRepairRequestApi,
} from "../actions/addressApi";
import { updateContactDetailsData } from "./contactsOperator";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import moment from "moment";

export const confirmationOfDateAndTime = (status, isPickup) => async (
  dispatch,
  getStore
) => {
  let showComponent;
  showComponent =
    status === "Keep"
      ? isPickup
        ? "confirmDeliveryAddress"
        : "confirmDetails"
      : status === "Change"
        ? isPickup
          ? "changePickupDateTime"
          : "changeDeliveryDateTime"
        : "";

  const initial = getStore().journeyMessages.flowProgress.percentage;
  if (showComponent === "confirmDeliveryAddress") {
    dispatch(
      updateFlowProgress(
        PROGRESS_DATA.CONFIRM_RETURN_DETAILS.title,
        PROGRESS_DATA.CONFIRM_RETURN_DETAILS.count,
        initial
      )
    );
  } else if (showComponent === "confirmDetails") {
    dispatch(
      updateFlowProgress(
        PROGRESS_DATA.CONFIRM_SCHEDULE.title,
        PROGRESS_DATA.CONFIRM_SCHEDULE.count,
        initial
      )
    );
  }
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", status),
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

export const confirmationOfAddress = (status, isPickup) => async (
  dispatch,
  getStore
) => {
  let showComponent = "";

  dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));

  if (status === "Confirm") {
    await dispatch(
      updateApiProgress(
        API_PROGRESS.CONFIRM_ADDRESS,
        40,
        COMPONENTS.UPDATE_ADDRESS
      )
    );

    const { CacheId } = getStore().session.sessionData.Configurations;
    const {
      selectedPickupAddress,
      selectedDeliveryAddress,
      pickUpAddressUpdated,
      deliveryAddressUpdated,
    } = getStore().claim.schedule;
    const address = isPickup ? selectedPickupAddress : selectedDeliveryAddress;
    const {
      Standardized,
      StandardizedSourceName,
      BaseAddressHeaderId,
      ...rest
    } = address;
    const addressObj = { ...rest };
    const isAddressAlreayUpdated = isPickup
      ? pickUpAddressUpdated
      : deliveryAddressUpdated;
    // API call to validate address here
    const confirmAddressResponse = await dispatch(
      standardizeAddressApi(CacheId, !isAddressAlreayUpdated, addressObj, false)
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(confirmAddressResponse)) return;

    // After API call
    await dispatch(
      updateApiProgress(
        API_PROGRESS.UPDATE_PICKUP_ADDRESS_SUCCESS,
        100,
        COMPONENTS.UPDATE_ADDRESS,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    // check if address is valid
    if (confirmAddressResponse.QASResult.isValidAddress) {
      const address =
        confirmAddressResponse.QASResult.AddressResult.CorrectedAddress;
      if (!isEmpty(address)) {
        await dispatch(updateCorrectedAddress(address, addressObj, isPickup));
      }
      showComponent = isPickup
        ? "confirmPickupDateTime"
        : "confirmDeliveryDateTime";
    } else {
      // address is not valid
      // show change address component
      showComponent = isPickup
        ? "changePickupAddress"
        : "changeDeliveryAddress";

      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.EnteredInvalidAddress",
          }),
        ])
      );
    }
  } else if (status === "Change") {
    showComponent = isPickup ? "changePickupAddress" : "changeDeliveryAddress";
  }
  dispatch(
    addJourneyMessages([
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

export const changeAddressSchedule = (address, isPickup) => async (
  dispatch,
  getStore
) => {
  let showComponent = "";
  let addressText = "";
  const {
    unitNumber,
    buildingName,
    blockNumber,
    streetName,
    postalCode,
  } = (address || {});

  if (isEmpty(address)) {
    addressText = "Cancel";
    showComponent = `confirm${isPickup ? "Pickup" : "Delivery"}Address`;
    await dispatch(addJourneyMessages([createMessage("TEXT", "user", addressText)]));
    dispatch(
      addJourneyMessages([
        createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
      ])
    );
    return;
  } else {
    addressText = `${unitNumber} ${buildingName} ${blockNumber} ${streetName} ${postalCode}`;
  }
  await dispatch(
    addJourneyMessages([createMessage("TEXT", "user", addressText)])
  );

  let addressObj = {
    Address1: `${unitNumber}, ${buildingName}`,
    Address2: blockNumber,
    Address3: streetName,
    PostalCode: postalCode,
    City: "Singapore",
    StateProvinceCode: "Singapore",
    Country: "Singapore",
    CountryCode: "SGP",
    AddressId: "",
  };

  // call standardrize api
  await dispatch(
    updateApiProgress(
      isPickup
        ? API_PROGRESS.UPDATE_PICKUP_ADDRESS
        : API_PROGRESS.UPDATE_DELIVERY_ADDRESS,
      40,
      COMPONENTS.UPDATE_ADDRESS,
      0
    )
  );
  const { CacheId } = getStore().session.sessionData.Configurations;
  // API call to change address here
  const changeAddressResponse = await dispatch(
    standardizeAddressApi(CacheId, false, addressObj, false)
  ).catch((err) => dispatch(serviceUnavailable()));
  if (isEmpty(changeAddressResponse)) return;

  // After API call
  await dispatch(
    updateApiProgress(
      isPickup
        ? API_PROGRESS.UPDATE_PICKUP_ADDRESS_SUCCESS
        : API_PROGRESS.UPDATE_DELIVERY_ADDRESS_SUCCESS,
      100,
      COMPONENTS.UPDATE_ADDRESS,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());

  // check for is address valid
  if (changeAddressResponse.QASResult.isValidAddress) {
    // save correct address
    const address =
      changeAddressResponse.QASResult.AddressResult.CorrectedAddress;
    showComponent = await dispatch(
      updateCorrectedAddress(address, addressObj, isPickup)
    );
  } else if (!changeAddressResponse.QASResult.isValidAddress) {
    // address is not valid
    const attemptCount = await dispatch(updateFailedAddressAttempt(isPickup));
    // check retry
    if (attemptCount < 3) {
      dispatch(
        addJourneyMessages([
          createMessage(
            "TEXT",
            "system",
            "Your address is invalid. Please Enter new Adress"
          ),
        ])
      );
      // change address component
      showComponent = isPickup
        ? "changePickupAddress"
        : "changeDeliveryAddress";
    } else {
      // show incorrect address component
      showComponent = isPickup
        ? "incorrectPickupAddress"
        : "incorrectDeliveryAddress";
    }
  }

  dispatch(
    updateVisitor({
      lastActivity: isPickup
        ? ACTIVITY.UPDATE_PICKUP_ADDRESS
        : ACTIVITY.UPDATE_DELIVERY_ADDRESS,
    })
  );
  dispatch(
    addJourneyMessages([
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

const updateCorrectedAddress = (
  correctedAddress,
  oldAddress,
  isPickup
) => async (dispatch) => {
  let showComponent = "";
  const correctedAddressObj = {
    Address1: correctedAddress.AddressLine1,
    Address2: correctedAddress.AddressLine2,
    Address3: correctedAddress.AddressLine3,
    PostalCode: correctedAddress.PostalCode,
    City: correctedAddress.CityName,
    StateProvinceCode: correctedAddress.StateProvinceCode,
    Country: correctedAddress.Country,
    CountryCode: correctedAddress.CountryCode,
    AddressId: "",
    Standardized: correctedAddress.Standardized,
    StandardizedSourceName: correctedAddress.StandardizedSourceName,
    BaseAddressHeaderId: correctedAddress.BaseAddressHeaderId,
  };

  if (isPickup) {
    await dispatch(savePickupAddress(correctedAddressObj, true));
    await dispatch(savePickupAddress(oldAddress));
    showComponent = "confirmPickupDateTime";
  } else {
    await dispatch(saveDeliveryAddress(correctedAddressObj, true));
    await dispatch(saveDeliveryAddress(oldAddress));
    showComponent = "confirmDeliveryDateTime";
  }
  return showComponent;
};

export const changeDateTimeSchedule = (date, slot, isPickup) => async (
  dispatch,
  getStore
) => {
  let showComponent = "";
  if (isEmpty(date) || isEmpty(slot)) {
    await dispatch(
      addJourneyMessages([createMessage("TEXT", "user", "Cancel")])
    );
    showComponent = `confirm${isPickup ? "Pickup" : "Delivery"}DateTime`;
  } else {
    await dispatch(
      addJourneyMessages([createMessage("TEXT", "user", "Submit")])
    );
    await dispatch(
      updateApiProgress(
        API_PROGRESS.UPDATE_SCHEDULE,
        40,
        COMPONENTS.UPDATE_SCHEDULE,
        0
      )
    );
    const initial = getStore().journeyMessages.flowProgress.percentage;
    const { isCampaignTimingEligible } = getStore().claim.schedule;

    if (isPickup) {
      if (isCampaignTimingEligible) {
        await dispatch(savePickupDateTime(date, slot));

        const deliveryOptions = getDeliveryDates(date, slot, true, true);
        const deliverySlot = deliveryOptions[0].slots[0];

        dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
      } else {
        const deliveryOptions = getDeliveryDates(date);
        const deliverySlot = deliveryOptions[0].slots[0];
        dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
        await dispatch(savePickupDateTime(date, slot));
      }
      
      showComponent = "confirmDeliveryAddress";
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.CONFIRM_RETURN_DETAILS.title,
          PROGRESS_DATA.CONFIRM_RETURN_DETAILS.count,
          initial
        )
      );
    } else {
      await dispatch(saveDeliveryDateTime(date, slot));
      showComponent = "confirmDetails";
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.CONFIRM_SCHEDULE.title,
          PROGRESS_DATA.CONFIRM_SCHEDULE.count,
          initial
        )
      );
    }
    await dispatch(
      updateApiProgress(
        API_PROGRESS.UPDATE_SCHEDULE_SUCCESS,
        100,
        COMPONENTS.UPDATE_SCHEDULE,
        40
      )
    );
    await sleep(1500);
    await dispatch(clearApiProgress());
    dispatch(
      updateVisitor({
        lastActivity: isPickup
          ? ACTIVITY.UPDATE_PICKUP_SCHEDULE
          : ACTIVITY.UPDATE_DELIVERY_SCHEDULE,
      })
    );
  }
  dispatch(
    addJourneyMessages([
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

// export const confirmationOfDetails = (status) => async (dispatch, getStore) => {
//   await dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
//   const enquiryOption = getStore().journeyMessages.enquiryOption;
//   let showComponent = "confirmPickupAddress",
//     module = "PICKUP_AND_DELIVERY";
//   if (status === "Confirm") {
//     module =
//       enquiryOption === EnumEnquiryModules.ChangeSchedule
//         ? "IMPORTANT_NOTE"
//         : [
//             EnumEnquiryModules.NewRequest,
//             EnumEnquiryModules.InWarrantyRequest,
//             EnumEnquiryModules.ResumeRequest,
//           ].includes(enquiryOption)
//         ? "CONTACT_CONFIRMATION"
//         : "";
//     dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_SCHEDULE }));
//   }
//   if (module === "CONTACT_CONFIRMATION") {
//     await dispatch(updateContactDetailsData());
//     await dispatch(
//       updateApiProgress(
//         API_PROGRESS.UPDATE_SCHEDULE,
//         40,
//         COMPONENTS.UPDATE_SCHEDULE,
//         0
//       )
//     );

//     const { selectedAsset } = getStore().claim.deviceConfirmation;
//     const { CacheId } = getStore().session.sessionData.Configurations;
//     const {
//       InteractionLineId,
//     } = getStore().session.interactionData.Interaction;
//     const {
//       ServiceRequestId,
//       CustomerCaseId,
//     } = getStore().serviceRequest.serviceRequestDetails;
//     const {
//       correctedPickupAddress,
//       correctedDeliveryAddress,
//       selectedPickupDate,
//       selectedPickupSlot,
//       selectedDeliveryDate,
//     } = getStore().claim.schedule;
//     const { firstName, lastName } = getStore().validation.inputData;
//     const customerName = `${firstName} ${lastName}`;

//     const {
//       RepairRequest,
//       ServiceOrder,
//     } = getStore().serviceRequest.determineIncidentDetails;

//     let ServiceOrderId;

//     // check for resume if service order is called before or not
//     // call service order api
//     if (isEmpty(ServiceOrder)) {
//       let serviceOrderResponse = await dispatch(
//         serviceOrderApi(
//           CacheId,
//           InteractionLineId,
//           ServiceRequestId,
//           CustomerCaseId,
//           selectedAsset.AssetCatalog.AssetCatalogId
//         )
//       ).catch((err) => dispatch(serviceUnavailable()));
//       if (isEmpty(serviceOrderResponse)) return;
//       ServiceOrderId =
//         serviceOrderResponse.CreateServiceOrderResults.ServiceOrderId;
//     } else {
//       ServiceOrderId = ServiceOrder.ServiceOrderId;
//     }

//     if (isEmpty(RepairRequest)) {
//       // call create repair request
//       let repairRequestResponse = await dispatch(
//         createRepairRequestApi(
//           CacheId,
//           CustomerCaseId,
//           ServiceRequestId,
//           ServiceOrderId
//         )
//       ).catch((err) => dispatch(serviceUnavailable()));
//       if (isEmpty(repairRequestResponse)) return;
//     }

//     // call get shipping order api
//     let shippingAddressResponse = await dispatch(
//       getShippingAddressApi(CacheId)
//     ).catch((err) => dispatch(serviceUnavailable()));
//     if (isEmpty(shippingAddressResponse)) return;

//     // call update shipping api
//     let updateShippingAddressResponse = await dispatch(
//       updateShippingAddressApi(
//         CacheId,
//         customerName,
//         ServiceOrderId,
//         ServiceRequestId,
//         selectedPickupDate,
//         selectedPickupSlot,
//         correctedPickupAddress,
//         selectedDeliveryDate,
//         correctedDeliveryAddress
//       )
//     ).catch((err) => dispatch(serviceUnavailable()));
//     if (isEmpty(updateShippingAddressResponse)) return;

//     await dispatch(
//       updateApiProgress(
//         API_PROGRESS.UPDATE_SCHEDULE_SUCCESS,
//         100,
//         COMPONENTS.UPDATE_SCHEDULE,
//         40
//       )
//     );
//     await sleep(1500);
//     await dispatch(clearApiProgress());

//     showComponent = "ConfirmMobileNumber";
//     const initial = getStore().journeyMessages.flowProgress.percentage;
//     dispatch(
//       updateFlowProgress(
//         PROGRESS_DATA.CONFIRM_CONTACT.title,
//         PROGRESS_DATA.CONFIRM_CONTACT.count,
//         initial
//       )
//     );
//   }
//   await dispatch(
//     addJourneyMessages([createMessage(module, "system", { showComponent })])
//   );
// };

export const createPickupOptions = (isDeviceAppleSamsung=false, isSurCampaign=false) => async (dispatch, getStore) => {
  // const format = 'hh:mm:ss';
  // const nowtime = moment();
  // const beforeTime = moment('12:00:00', format);

  // const isCampaignTimingEligible = isSurCampaign && nowtime.isBefore(beforeTime) && isDeviceAppleSamsung;
  const is1DayTAT = isDeviceAppleSamsung;
  const isCampaignTimingEligible = (isSurCampaign && isDeviceAppleSamsung) || is1DayTAT;
  

  dispatch(setisCampaignTimingEligible(isCampaignTimingEligible))

  const assetData = getStore().validation?.verification?.enrolledAsset;
  const deviceMake = assetData?.Make?.Name;
  const isDeviceMakeHuaweiOrXiaomi = (makeName) => {
    if (makeName) {
      return (
        makeName.toUpperCase() === DEVICE.HUAWEI ||
        makeName.toUpperCase() === DEVICE.XIAOMI
      );
    }
    return false;
  };

  const options = isDeviceMakeHuaweiOrXiaomi(deviceMake) ? getMonFriPickupDates(new Date()) : getPickupDates(new Date(), isCampaignTimingEligible);
  dispatch({
    type: setPickupOptions.toString(),
    payload: options,
  });
  return options;
};

export const initializePickupSchedule = (isDeviceAppleSamsung=false) => async (dispatch, getStore) => {
  const {
    Type,
    ClaimedAsset,
  } = getStore().serviceRequest.determineIncidentDetails;

  const { selectedAsset } = getStore().claim?.deviceConfirmation;

  const isSelectedAssetAppleSamsung = selectedAsset?.Make?.Name?.toLowerCase() === "apple" || selectedAsset?.Make?.Name?.toLowerCase() === "samsung";

  const isAssetFromDetermineAppleSamsung = Type === "ResumeServiceRequest" && ClaimedAsset && ClaimedAsset.Make 
  ? (ClaimedAsset.Make.toLowerCase() === "apple" || ClaimedAsset.Make.toLowerCase() === "samsung") 
  : false

  const surCampaignEnd = process.env.REACT_APP_SUR_CAMPAIGN_END;
  const isSurCampaignEndDateActive = moment().isSameOrBefore(moment(surCampaignEnd), "day")
  const isSurCampaign = process.env.REACT_APP_SUR_CAMPAIGN_ENABLED === "true" && isSurCampaignEndDateActive;
  const address = getStore().validation.agreement?.EnrolledAddress || getStore().validation.agreement?.Agreement?.EnrolledAddress;
  await dispatch(savePickupAddress(address));
  await dispatch(saveDeliveryAddress(address));
  await dispatch(saveRegisteredAddress(address));
  const pickupOptions = await dispatch(createPickupOptions((isAssetFromDetermineAppleSamsung || isDeviceAppleSamsung || isSelectedAssetAppleSamsung), isSurCampaign));
  const pickupSlot = pickupOptions[0].slots[0];
  const deliveryOptions = getDeliveryDates(pickupOptions[0].date, pickupSlot, (isAssetFromDetermineAppleSamsung || isDeviceAppleSamsung || isSelectedAssetAppleSamsung), isSurCampaign);
  const deliverySlot = deliveryOptions[0].slots[0];
  await dispatch(savePickupDateTime(pickupOptions[0].date, pickupSlot));
  await dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
  return;
};

export const confirmRegisteredAddress = (status, isPickup) => async (
  dispatch,
  getStore
) => {
  const which = isPickup ? "Pick-up" : "Return";
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  if (status === "No") {
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.UnableToProcessRequest",
        }),
      ])
    );
    dispatch(confirmToCancelRequest("", `Invalid ${which} Address`, true));
  } else {
    const {
      selectedPickupAddress,
      selectedDeliveryAddress,
    } = getStore().claim.schedule;
    const module = "PICKUP_AND_DELIVERY";
    let showComponent = "";
    if (isPickup) {
      showComponent = "confirmPickupDateTime";
      dispatch(savePickupAddress(selectedPickupAddress, true));
    } else {
      showComponent = "confirmDeliveryDateTime";
      dispatch(saveDeliveryAddress(selectedDeliveryAddress, true));
    }
    dispatch(
      addJourneyMessages([createMessage(module, "system", { showComponent })])
    );
  }
};

export const saveDeliveryDateTime = (date, slot) => async (dispatch) => {
  await dispatch({
    type: setDeliveryDateTime.toString(),
    payload: { date, slot },
  });
  return date;
};

export const savePickupDateTime = (date, slot) => async (dispatch) => {
  await dispatch({
    type: setPickupDateTime.toString(),
    payload: { date, slot },
  });
  return date;
};

export const savePickupAddress = (address, isCorrected = false) => async (
  dispatch
) => {
  await dispatch({
    type: isCorrected
      ? setCorrectedPickupAddress.toString()
      : setPickupAddress.toString(),
    payload: { address },
  });
  return address;
};

export const saveDeliveryAddress = (address, isCorrected = false) => async (
  dispatch
) => {
  await dispatch({
    type: isCorrected
      ? setCorrectedDeliveryAddress.toString()
      : setDeliveryAddress.toString(),
    payload: { address },
  });
  return address;
};

export const saveRegisteredAddress = (address) => async (dispatch) => {
  await dispatch({
    type: setRegisteredAddress.toString(),
    payload: address,
  });
  return address;
};

const updateFailedAddressAttempt = (isPickup) => async (dispatch, getStore) => {
  const {
    failedPickupAddressAttempt,
    failedDeliveryAddressAttempt,
  } = getStore().claim.schedule;
  const count =
    (isPickup ? failedPickupAddressAttempt : failedDeliveryAddressAttempt) + 1;
  const attemptType = isPickup
    ? setFailedPickupAddressAttempt
    : setFailedDeliveryAddressAttempt;
  dispatch({
    type: attemptType.toString(),
    payload: count,
  });
  return count;
};

/**
 * 
 * This is only used for pure API calls no redirection
 * Since we need to call serviceOrder to shippingOrder APi after contactPoints
 * therefore this will be called later after success of contactPoint API
 */
export const confirmationOfDetails = () => async (dispatch, getStore) => {
  await dispatch(updateContactDetailsData());
  await dispatch(
    updateApiProgress(
      API_PROGRESS.UPDATE_SCHEDULE,
      40,
      COMPONENTS.UPDATE_SCHEDULE,
      0
    )
  );

  const { selectedAsset } = getStore().claim.deviceConfirmation;
  const { CacheId } = getStore().session.sessionData.Configurations;
  const {
    InteractionLineId,
  } = getStore().session.interactionData.Interaction;
  const {
    ServiceRequestId,
    CustomerCaseId,
  } = getStore().serviceRequest.serviceRequestDetails;
  const {
    correctedPickupAddress,
    correctedDeliveryAddress,
    selectedPickupDate,
    selectedPickupSlot,
    selectedDeliveryDate,
  } = getStore().claim.schedule;
  const { firstName, lastName } = getStore().validation.inputData;
  const customerName = `${firstName} ${lastName}`;

  const {
    RepairRequest,
    ServiceOrder,
  } = getStore().serviceRequest.determineIncidentDetails;

  let ServiceOrderId;

  // check for resume if service order is called before or not
  // call service order api
  if (isEmpty(ServiceOrder)) {
    let serviceOrderResponse = await dispatch(
      serviceOrderApi(
        CacheId,
        InteractionLineId,
        ServiceRequestId,
        CustomerCaseId,
        selectedAsset.AssetCatalog.AssetCatalogId
      )
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(serviceOrderResponse)) return false;
    ServiceOrderId =
      serviceOrderResponse.CreateServiceOrderResults.ServiceOrderId;
  } else {
    ServiceOrderId = ServiceOrder.ServiceOrderId;
  }

  if (isEmpty(RepairRequest)) {
    // call create repair request
    let repairRequestResponse = await dispatch(
      createRepairRequestApi(
        CacheId,
        CustomerCaseId,
        ServiceRequestId,
        ServiceOrderId
      )
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(repairRequestResponse)) return false;
  }

  // call get shipping order api
  // let shippingAddressResponse = await dispatch(
  //   getShippingAddressApi(CacheId)
  // ).catch((err) => dispatch(serviceUnavailable()));
  // if (isEmpty(shippingAddressResponse)) return false;
  let shippingAddressResponse = null;
  let retrycount = 0;
  while(retrycount < 2) {
    shippingAddressResponse = await dispatch(getShippingAddressApi(CacheId))
    .catch((err) =>
      { return null }
    );

    if (shippingAddressResponse === null) {
      retrycount++;
    } else {
      retrycount = 2;
    }
  }
  // not allowing to go ahead if response is empty
  if (isEmpty(shippingAddressResponse)) {
    dispatch(serviceUnavailable())
    return false;
  }

  // call update shipping api
  let updateShippingAddressResponse = await dispatch(
    updateShippingAddressApi(
      CacheId,
      customerName,
      ServiceOrderId,
      ServiceRequestId,
      selectedPickupDate,
      selectedPickupSlot,
      correctedPickupAddress,
      selectedDeliveryDate,
      correctedDeliveryAddress
    )
  ).catch((err) => dispatch(serviceUnavailable()));
  if (isEmpty(updateShippingAddressResponse)) return false;

  await dispatch(
    updateApiProgress(
      API_PROGRESS.UPDATE_SCHEDULE_SUCCESS,
      100,
      COMPONENTS.UPDATE_SCHEDULE,
      40
    )
  );
  await sleep(1500);
  await dispatch(clearApiProgress());

  return true;
};

/**
 * 
 * Now contactDetails API will be before shippingOrder API
 * Hence this is only used for redirect purpose
 */
export const redirectToPhoneNumber = (status, isAfterCutoffError = false) => async (dispatch, getStore) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  const enquiryOption = getStore().journeyMessages.enquiryOption;
  let showComponent = "confirmPickupAddress";
  let module = "PICKUP_AND_DELIVERY";
  const options = [
    EnumEnquiryModules.NewRequest,
    EnumEnquiryModules.InWarrantyRequest,
    EnumEnquiryModules.ResumeRequest,
  ];

  if (isAfterCutoffError) {
    let updateShippingOrderResponse = await dispatch(updateShippingOrderNewSchedule())

    if (!updateShippingOrderResponse) return;

    module = "REPAIR_DETAILS";
    showComponent = "";
  } else {
    if (status === "Confirm") {
      module =
        enquiryOption === EnumEnquiryModules.ChangeSchedule
          ? "IMPORTANT_NOTE"
          : options.includes(enquiryOption)
            ? "CONTACT_CONFIRMATION"
            : "";
      dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_SCHEDULE }));
    }
  
    if (module === "CONTACT_CONFIRMATION") {
      showComponent = "ConfirmMobileNumber";
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.CONFIRM_CONTACT.title,
          PROGRESS_DATA.CONFIRM_CONTACT.count,
          initial
        )
      );
    }
  }

  await dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
}

export const updateShippingOrderNewSchedule = () => async (dispatch, getStore) => {
  await dispatch(
    updateApiProgress(
      API_PROGRESS.UPDATE_SCHEDULE,
      40,
      COMPONENTS.UPDATE_SCHEDULE,
      0
    )
  );
  const { CacheId } = getStore().session.sessionData.Configurations;

  const {
    ServiceRequestId,
  } = getStore().serviceRequest.serviceRequestDetails;

  const {
    correctedPickupAddress,
    correctedDeliveryAddress,
    selectedPickupDate,
    selectedPickupSlot,
    selectedDeliveryDate,
    serviceOrder
  } = getStore().claim.schedule;

  const { firstName, lastName } = getStore().validation.inputData;
  const customerName = `${firstName} ${lastName}`;
 
  // call update shipping api
  let updateShippingAddressResponse = await dispatch(
    updateShippingAddressApi(
      CacheId,
      customerName,
      serviceOrder.CreateServiceOrderResults.ServiceOrderId,
      ServiceRequestId,
      selectedPickupDate,
      selectedPickupSlot,
      correctedPickupAddress,
      selectedDeliveryDate,
      correctedDeliveryAddress
    )
  ).catch((err) => {
    dispatch(serviceUnavailable());
    return null;
  }
  );
  if (isEmpty(updateShippingAddressResponse)) {
    await dispatch(clearApiProgress());
    return false;
  };

  await dispatch(
    updateApiProgress(
      API_PROGRESS.UPDATE_SCHEDULE_SUCCESS,
      100,
      COMPONENTS.UPDATE_SCHEDULE,
      40
    )
  );
  await sleep(1500);
  await dispatch(clearApiProgress());
  return true;
};