import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import { cancelServiceRequest } from "../../../../actions/serviceRequestApi";
import updateVisitor from "../../../Appsync/updateVisitor";
import {
  ACTIVITY,
  ONLINE_JOURNEY_STATUS_LIST,
} from "../../../../helpers/constants";
import { isEmpty } from "../../../../helpers/formatUtils";
import { endFlow } from "../../../../actions/flowWindow";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";

export const confirmToCancelRequest = (
  status,
  subReason = "Cancelled by user",
  showCancelledComponent = false
) => (dispatch, getStore) => {
  if (!isEmpty(status)) {
    dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  }
  const { CacheId } = getStore().session.sessionData.Configurations;
  const enquiryOption = getStore().journeyMessages.enquiryOption;

  const { ServiceRequestId, CustomerCaseId } =
    enquiryOption === EnumEnquiryModules.CancelRequest
      ? getStore().serviceRequest.determineIncidentDetails
      : getStore().serviceRequest.serviceRequestDetails;

  const {
    ServiceOrder,
    RepairRequest,
    ChargeOrder,
  } = getStore().serviceRequest.determineIncidentDetails;

  let ServiceOrderId = "";
  // get service order id if available
  if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
    // get it from service order api response
    ServiceOrderId = getStore().claim.schedule.serviceOrder
      .CreateServiceOrderResults.ServiceOrderId;
  } else if (!isEmpty(ServiceOrder)) {
    // get it from determine incident api response
    ServiceOrderId = ServiceOrder.ServiceOrderId;
  }

  // default cancel action
  let cancelAction = "CancelWorkingClaim";

  if (
    ChargeOrder &&
    RepairRequest &&
    (RepairRequest.RepairStatus === "RESERVED" ||
      RepairRequest.RepairStatus === "SCHEDULED")
  ) {
    // cancel action if claim is submitted
    cancelAction = "Cancelsubmitclaim";
  }

  const note = "Request Cancelled by User";
  let module = "ENQUIRY_OPTIONS",
    showComponent;
  let systemMsg = "";
  // Need to change following line with actual redux state one Scheduling API is integrated.
  const isPickupCompleted = false;
  if (status === "Yes" || showCancelledComponent) {
    if (!isPickupCompleted || showCancelledComponent) {
      module = "CANCEL_REPAIR";
      showComponent = "requestCancelled";
      systemMsg = "RequestCancelled";
    } else {
      systemMsg = "CancellationRequestSubmitted";
    }
  }
  dispatch(
    cancelServiceRequest(
      CacheId,
      ServiceRequestId,
      note,
      CustomerCaseId,
      subReason,
      ServiceOrderId,
      cancelAction
    )
  );
  dispatch(
    updateVisitor({
      lastActivity: ACTIVITY.CANCEL_REQUEST,
      journeyStatus: ONLINE_JOURNEY_STATUS_LIST.COMPLETED,
    })
  );
  if (status === "Yes" || !isEmpty(showComponent) || showCancelledComponent) {
    dispatch(
      addJourneyMessages([createMessage(module, "system", { showComponent })])
    );
  }
  if (!isEmpty(systemMsg) || showCancelledComponent) {
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: `SystemMessage.${systemMsg}`,
        }),
      ])
    );
  }
  if (status === "Yes" || showCancelledComponent) {
    dispatch(endFlow());
  }
};

export const discardCancelRequest = (status) => async (dispatch) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  await sleep(2000);
  dispatch(
    addJourneyMessages([createMessage("ENQUIRY_OPTIONS", "system", {})])
  );
};
