import { isEmpty } from "./formatUtils";
import holidayList from "./holidayList.json";
import moment from "moment";

const aDay = 86400000;

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septembet",
  "October",
  "November",
  "December",
];

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// Default start and end timings
const defaultStartTime = "09:00:00";
const defaultEndTime = "21:00:00";

const Holidays = holidayList.map((d) => new Date(d).toString());

const monToFri = (day) => {
  return day > 0 && day < 6;
};

const isSaturday = (day) => {
  return day === 6;
};

const isFriday = (day) => {
  return day === 5;
};

export const isMondayOrFriday = (day) => {
  return day === 1 || day === 5;
}

export const getPickupSlot = (isCampaignTimingEligible = false) => {
  if (isCampaignTimingEligible) {
    return ["12pm - 2pm", "10am - 2pm", "2pm - 6pm"]
  }
  return ["9am - 12pm", "12pm - 4pm"]
};

export const getDeliverySlot = () => ["2pm - 6pm"];

export const covertToUTCDate = (date = new Date()) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  ).toString();
};

export const isPublicHoliday = (strDate) => {
  const givenDate = new Date(strDate);
  const holiday = Holidays.find((h) => {
    const hDate = new Date(h);
    return hDate.toLocaleDateString() === givenDate.toLocaleDateString();
  });
  return !isEmpty(holiday);
};

export const getNextDay = (strDate, nthDay = 1) => {
  return new Date(new Date(strDate).getTime() + aDay * nthDay).toString();
};

export const getNextBusinessDay = (
  strDate,
  nthDay = 1,
  includeSaturday = false
) => {
  let daysCovered = 0;
  let newDay = strDate;

  while (daysCovered !== nthDay) {
    newDay = getNextDay(newDay);
    const weekDay = new Date(newDay).getDay();
    if (
      !isPublicHoliday(newDay) &&
      (monToFri(weekDay) || (isSaturday(weekDay) && includeSaturday))
    ) {
      daysCovered++;
    }
  }
  return newDay;
};

export const getNextMonFriBusinessDay = (
  strDate,
  nthDay = 1,
) => {
  let daysCovered = 0;
  let newDay = strDate;

  while (daysCovered !== nthDay) {
    newDay = getNextDay(newDay);
    const weekDay = new Date(newDay).getDay();
    if (
      !isPublicHoliday(newDay) &&
      (isMondayOrFriday(weekDay))
    ) {
      daysCovered++;
    }
  }
  return newDay;
};

export const getPickupDates = (date, isCampaignTimingEligible = false) => {
  const today = getDateFromUtcTimeZone(8, date);
  const todayStr = today.toString();
  const hour = today.getHours();
  const minutes = today.getMinutes();
  const day = today.getDay();
  const slots = getPickupSlot();
  let options = [];
  const isBefore7_45AM = hour < 7 || (hour === 7 && minutes < 45);
  const isBefore1_45PM =
    !isBefore7_45AM && (hour < 13 || (hour === 13 && minutes < 45));

    const format = 'hh:mm:ss';
    const TimeBefore12NN = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_1_BEFORE || '11:45', format);
    const TimeAfter12NN = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_2_START || '11:45', format);
    const TimeBefore2PM = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_2_END || '13:45', format);
    const TimeAfter2PM = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_3_AFTER || '11:45', format);
    const nowtime = moment();

    const is1DayTAT = true;//extend 1 day TAT after campaign

  if (!isPublicHoliday(todayStr) && monToFri(day)) {
    if (isCampaignTimingEligible) {
      const campaignSlots = getPickupSlot(isCampaignTimingEligible);

      if (nowtime.isBefore(TimeBefore12NN)) {
        options.push(
          { date: todayStr, slots: [campaignSlots[0]] },
          { date: getNextBusinessDay(todayStr), slots: [campaignSlots[1], campaignSlots[2]] },
          { date: getNextBusinessDay(todayStr, 2), slots: [campaignSlots[1], campaignSlots[2]] }
        );
      } else if (nowtime.isBetween(TimeAfter12NN, TimeBefore2PM)) {
        options.push(
          { date: todayStr, slots: [campaignSlots[2]] },
          { date: getNextBusinessDay(todayStr, 1), slots: [campaignSlots[1], campaignSlots[2]] },
          { date: getNextBusinessDay(todayStr, 2), slots: [campaignSlots[1], campaignSlots[2]] }
        );
      } else if (nowtime.isAfter(TimeAfter2PM)) {
        options.push(
          { date: getNextBusinessDay(todayStr, 1), slots: [campaignSlots[1], campaignSlots[2]] },
          { date: getNextBusinessDay(todayStr, 2), slots: [campaignSlots[1], campaignSlots[2]] },
          { date: getNextBusinessDay(todayStr, 3), slots: [campaignSlots[1], campaignSlots[2]] }
        );
      } else {
        options.push(
          { date: getNextBusinessDay(todayStr, 1), slots: [campaignSlots[1], campaignSlots[2]] },
          { date: getNextBusinessDay(todayStr, 2), slots: [campaignSlots[1], campaignSlots[2]] },
          { date: getNextBusinessDay(todayStr, 3), slots: [campaignSlots[1], campaignSlots[2]] }
        );
      }
    } else {
      if (isBefore7_45AM) {
        options.push(
          { date: todayStr, slots },
          { date: getNextBusinessDay(todayStr), slots },
          { date: getNextBusinessDay(todayStr, 2), slots }
        );
      } else if (isBefore1_45PM) {
        options.push(
          { date: todayStr, slots: [slots[1]] },
          { date: getNextBusinessDay(todayStr), slots },
          { date: getNextBusinessDay(todayStr, 2), slots }
        );
      } else {
        options.push(
          { date: getNextBusinessDay(todayStr), slots },
          { date: getNextBusinessDay(todayStr, 2), slots },
          { date: getNextBusinessDay(todayStr, 3), slots }
        );
      }
    }
  } else {
    if (isCampaignTimingEligible) {
      const campaignSlots = getPickupSlot(isCampaignTimingEligible);

      options.push(
        { date: getNextBusinessDay(todayStr, 1), slots: [campaignSlots[1], campaignSlots[2]] },
        { date: getNextBusinessDay(todayStr, 2), slots: [campaignSlots[1], campaignSlots[2]] },
        { date: getNextBusinessDay(todayStr, 3), slots: [campaignSlots[1], campaignSlots[2]] }
      );
    } else {
      options.push(
        { date: getNextBusinessDay(todayStr), slots },
        { date: getNextBusinessDay(todayStr, 2), slots },
        { date: getNextBusinessDay(todayStr, 3), slots }
      );
    }
  }
  return options;
};

export const getMonFriPickupDates = (dateObj) => {
  const slots = getPickupSlot();
  let options = [];
  let startDateObj = dateObj;

  function getNextAvailableMonFri(whichdateObj){
    const which = getDateFromUtcTimeZone(8, whichdateObj);
    const whichdayStr = which.toString();
    const hour = which.getHours();
    const minutes = which.getMinutes();
    const whichday = which.getDay();
    const isBefore7_45AM = hour < 7 || (hour === 7 && minutes < 45);
    const isBefore1_45PM =
      !isBefore7_45AM && (hour < 13 || (hour === 13 && minutes < 45));
    let nextDateStr = null;

    if (!isPublicHoliday(whichdayStr) && isMondayOrFriday(whichday)) {
      if (!options.length) {
        if (isBefore7_45AM) {
          options.push(
            { date: whichdayStr, slots }
          );
        } else if (isBefore1_45PM) {
          options.push(
            { date: whichdayStr, slots: [slots[1]] },
          );
        } else {
          nextDateStr = getNextMonFriBusinessDay(whichdayStr)
          options.push(
            { date: nextDateStr, slots }
          );
        }
      } else {
        options.push(
          { date: whichdayStr, slots }
        );
      }
    }

    let baseDateStr = nextDateStr || whichdayStr;
    let newStartDateObj = new Date(baseDateStr);
    newStartDateObj.setDate(newStartDateObj.getDate() + 1);
    startDateObj = newStartDateObj;
  }

  while(options.length < 3) {
    console.log("options.length", options.length)
    getNextAvailableMonFri(startDateObj);
  }
  
  return options;
};

export const getDeliveryDates = (pickupDate, pickupSlot, isDeviceAppleSamsung = false, isSurCampaign = false) => {
  const is1DayTAT = isDeviceAppleSamsung;
  const isCampaignTimingEligible = (isSurCampaign && isDeviceAppleSamsung) || is1DayTAT;

  const date = new Date(pickupDate);
  const day = date.getDay();
  const slots = getDeliverySlot();
  let options = [];
  if (isFriday(day)) {
    if (isCampaignTimingEligible) {
      if (pickupSlot === '12pm - 2pm' || pickupSlot === '10am - 2pm') {
        options.push(
          { date: getNextBusinessDay(pickupDate, 1, true), slots },
          { date: getNextBusinessDay(pickupDate, 2, true), slots },
          { date: getNextBusinessDay(pickupDate, 3, true), slots }
        );
      } else {
        options.push(
          { date: getNextBusinessDay(pickupDate, 2), slots },
          { date: getNextBusinessDay(pickupDate, 3), slots },
          { date: getNextBusinessDay(pickupDate, 4), slots }
        );
      }
    } else {
      options.push(
        { date: getNextBusinessDay(pickupDate, 2), slots },
        { date: getNextBusinessDay(pickupDate, 3), slots },
        { date: getNextBusinessDay(pickupDate, 4), slots }
      );
    }
  } else {
    if (isCampaignTimingEligible) {
      if (pickupSlot === '12pm - 2pm' || pickupSlot === '10am - 2pm') {
        options.push(
          { date: getNextBusinessDay(pickupDate, 1, true), slots },
          { date: getNextBusinessDay(pickupDate, 2, true), slots },
          { date: getNextBusinessDay(pickupDate, 3, true), slots }
        );
      } else {
        options.push(
          { date: getNextBusinessDay(pickupDate, 2, true), slots },
          { date: getNextBusinessDay(pickupDate, 3, true), slots },
          { date: getNextBusinessDay(pickupDate, 4, true), slots }
        );
      }
    } else {
      options.push(
        { date: getNextBusinessDay(pickupDate, 2, true), slots },
        { date: getNextBusinessDay(pickupDate, 3, true), slots },
        { date: getNextBusinessDay(pickupDate, 4, true), slots }
      );
    }
  }
  return options;
};

export const getDateFromUtcTimeZone = (offset, date = new Date()) => {
  const offsetMultiplier = 3600000;
  const utcMs = new Date(covertToUTCDate(date)).getTime();
  const returnDate = new Date(utcMs + offsetMultiplier * offset);
  return returnDate;
};

export const getReadableDate = (dateStr) => {
  return new Date(dateStr).toDateString();
};

export const getFormmatedDate = (strDate, format = "d m yy", split = " ") => {
  const formats = format.split(split);
  const dateArray = [];
  const dateObj = new Date(strDate);
  const day = dateObj.getDay();
  const dayText = Days[day];
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const monthText = Months[month];
  const year = dateObj.getFullYear();
  formats.map((f) => {
    switch (f) {
      case "d":
        dateArray.push(date);
        return date;
      case "dd":
        const dd = ("0" + date).slice(-2);
        dateArray.push(dd);
        return dd;
      case "D":
        dateArray.push(dayText);
        return dayText;
      case "DDD":
        const dtext = dayText.substr(0, 3);
        dateArray.push(dtext);
        return dtext;
      case "m":
        dateArray.push(month + 1);
        return month + 1;
      case "mm":
        const mm = ("0" + (month + 1)).slice(-2);
        dateArray.push(mm);
        return mm;
      case "M":
        dateArray.push(monthText);
        return monthText;
      case "MMM":
        const mtext = monthText.substr(0, 3);
        dateArray.push(mtext);
        return mtext;
      case "yyyy":
        dateArray.push(year);
        return year;
      case "yy":
        const ytext = year.substr(2, 2);
        dateArray.push(ytext);
        return ytext;
      default:
        return "";
    }
  });
  return dateArray.join(split);
};

export const isToday = (someDate) => {
  const today = new Date();
  const parsedSomeDate = new Date(someDate);
  return parsedSomeDate.toLocaleDateString() === today.toLocaleDateString();
};

// Adding given time to current date
const setDateTime = (date, time) => {
  const cloned = new Date(date.getTime());
  // Adding hours, minutes and seconds to given cloned date
  const [hours, minutes, seconds = 0] = time.split(":");
  cloned.setHours(Number(hours), Number(minutes), Number(seconds));
  return cloned;
} 

export const isTimeBetweenBusinessHour = (sTime = 9, eTime = 21) => {
  const today = getDateFromUtcTimeZone(8);
  // Current date with start and end time for business hours
 const todayStartTime = setDateTime(today, sTime);
 const todayEndTime = setDateTime(today, eTime);

 return today.getTime() >= todayStartTime.getTime() && today.getTime() < todayEndTime.getTime();
};

export const isValidExpiryDate = (month, year) => {
  const givenMonth = parseInt(month);
  const givenYear = parseInt(year);
  const today = getDateFromUtcTimeZone(8);
  const currentMonth = parseInt(today.getMonth()) + 1;
  const currentYear = parseInt(today.getFullYear());
  return (
    givenMonth > 0 &&
    givenMonth < 13 &&
    (givenYear > currentYear || (givenMonth > currentMonth && givenYear === currentYear))
  );
};

export function showMaintenanceText () {
  let d1 = new Date("2021-05-24 06:00");
    let d2 = new Date();

    return d1 > d2 ;
}

/**
 * 
 * @param {Object} businessHours 
 * @returns {startTime, endTime}
 * startTime: if business hours found for given day returns start time
 * endTime: if business hours found for given day returns end time
 */
 export const getBusinessStartAndEndTime = (businessHours) => {
  const { Active: isActive } = businessHours;
  const today = Days[new Date().getDay()];
  const startDay = `${today}StartTime`;
  const endDay = `${today}EndTime`;
  const [startTime, endTime] = Object.keys(businessHours).filter(val => [startDay, endDay].includes(val));
  
  return {
    isActive,
    startTime: startTime && businessHours[startTime],
    endTime: endTime && businessHours[endTime]
  }
} 
